<script setup lang="ts">
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VBadge from "@magnit/core/src/components/VBadge/VBadge.vue";
import BonusIcon from "@magnit/icons/src/assets/icons/loyalty-bonus-16-color.svg";
import MagnetIcon from "@magnit/icons/src/assets/icons/loyalty-magnitik-16-color.svg";
import type { IProductProps } from "./VProduct.types";
import { useUserStore } from "~/store/user";
import { formatNumberWithSpaces } from "~/utils/etc";
import { formatPrice } from "~/utils/finance";

const props = defineProps<IProductProps>();
const userStore = useUserStore();

const { imageSrc } = useLoadProductImage(props.image);
const needAdultApprove = computed(() => {
  return props.adult && !userStore.showAdultContent;
});
const displayedProgressive = computed(
  () =>
    props.progressiveDiscountVariants?.[
      props.progressiveDiscountVariants?.length - 1 || 0
    ],
);
</script>

<template>
  <div class="product" :title="title">
    <div class="product-gallery">
      <div class="product-image">
        <VAdultImage
          v-if="needAdultApprove"
          :src="imageSrc"
          :alt="title"
          @adult-click="userStore.openAdultDialog"
        />
        <NuxtImg v-else :src="imageSrc" :alt="title" loading="lazy" />

        <div
          v-if="
            salePercent || salePeriod || progressiveDiscountType === 'percent'
          "
          class="product-sale"
          :class="{
            'product-sale_has-period': Boolean(salePeriod),
          }"
        >
          <div
            v-if="salePercent || displayedProgressive?.label"
            class="product-sale__percent"
          >
            <VText
              color="invert-primary"
              :font="{ xs: 'caption-large-accent', l: 'body-small-accent' }"
            >
              {{ salePercent || displayedProgressive!.label }}
            </VText>
          </div>

          <VText
            v-if="salePeriod"
            tag="div"
            :font="{ xs: 'caption-large-regular', l: 'body-small-regular' }"
            class="product-sale__period"
          >
            {{ salePeriod }}
          </VText>
        </div>

        <div v-if="badge" class="product-badges">
          <VBadge class="product-badge" theme="cosmetic" size="adaptive"
            >{{ badge }}
          </VBadge>
        </div>
      </div>
    </div>

    <div class="product-description">
      <div>
        <div v-if="displayedProgressive" class="product-progressive-variant">
          <VText font="caption-large-regular">{{
            displayedProgressive.countLabel
          }}</VText>
        </div>

        <div
          v-if="price && progressiveDiscountType !== 'price'"
          class="product-prices"
        >
          <VText :font="{ xs: 'body-large-accent', l: 'title-medium' }">
            <span v-html="formatPrice(price)" />
          </VText>

          <VText
            v-if="oldPrice"
            class="product-prices__sale"
            color="secondary"
            font="body-small-regular-low"
          >
            <span v-html="formatPrice(oldPrice)" />
          </VText>
        </div>

        <div
          v-if="displayedProgressive && progressiveDiscountType === 'price'"
          class="product-prices"
        >
          <VText :font="{ xs: 'body-large-accent', l: 'title-medium' }">
            <span
              v-html="
                formatPrice(
                  formatNumberWithSpaces(displayedProgressive.value / 100),
                )
              "
            />
          </VText>
        </div>

        <div v-if="displayedProgressive" class="product-progressive">
          <div
            v-if="progressiveDiscountType === 'bonuses'"
            class="product-progressive-string"
          >
            <VText font="body-small-accent"
              >+ {{ displayedProgressive.value }}</VText
            >
            <VIcon><BonusIcon /></VIcon>
            <VText font="caption-large-regular">бонусами</VText>
          </div>

          <div
            v-if="progressiveDiscountType === 'magnets'"
            class="product-progressive-string"
          >
            <VText font="body-small-accent"
              >+ {{ displayedProgressive.value }}</VText
            >
            <VIcon><MagnetIcon /></VIcon>
            <VText font="caption-large-regular">Магнитиками</VText>
          </div>

          <div
            v-if="progressiveDiscountType === 'cashback'"
            class="product-progressive-string"
          >
            <VText font="body-small-accent"
              >+ {{ displayedProgressive.value }}%</VText
            >
            <VIcon><BonusIcon /></VIcon>
            <VText font="caption-large-regular">бонусами</VText>
          </div>
        </div>

        <div v-if="title" class="product-title">
          <VText
            :font="{ xs: 'body-small-regular-low', l: 'body-small-regular' }"
          >
            {{ title }}
          </VText>
        </div>
      </div>
      <VText v-if="unit" font="body-small-regular" color="secondary">
        {{ unit }}
      </VText>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  background: var(--pl-surface-muted-default);
  border-radius: 18px;

  &:hover {
    background: var(--pl-surface-muted-hover);
  }

  &:active {
    background: var(--pl-surface-muted-pressed);
  }
}

.product-progressive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}

.product-progressive-variant {
  display: inline-flex;
  min-width: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 var(--pl-unit-x1);
  border-radius: var(--pl-unit-x1);
  border: 1px solid var(--pl-border-secondary-pressed);
}

.product-progressive-string {
  display: inline-flex;
  vertical-align: center;
  align-items: center;
  padding-top: var(--pl-unit-x1);
  gap: var(--pl-unit-x1);
  white-space: nowrap;

  & > span {
    white-space: nowrap;
  }
}

.product-gallery {
  position: relative;
  padding: 4px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 16px;
  padding: 8px;
  background: #fff;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    pointer-events: none;
  }
}

.product-badges {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.product-badge {
  border-bottom-left-radius: 0;
}

.product-sale {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &_has-period {
    background-color: var(--pl-surface-subdued-default);
  }

  &__percent,
  &__period {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    height: 20px;
    min-width: 45px;
  }

  &__percent {
    background: var(--pl-label-background-discount);
    border-radius: 0 16px;
    z-index: var(--pl-z-index-level2);
  }

  &__period {
    background: var(--pl-surface-subdued-default);
    border-radius: 0 16px 16px;
    position: relative;
    z-index: var(--pl-z-index-level1);

    .product-sale__percent + & {
      padding-left: 20px;
      left: -16px;
    }
  }
}

.product-description {
  padding: var(--pl-unit-x1) 6px var(--pl-unit-x2);
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.product-prices {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 8px;
  margin-top: 8px;

  &__sale {
    display: inline-block;
    text-decoration: line-through;
  }
}

.product-title {
  margin-top: 4px;
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (--pl-viewport-from-ml) {
  .product-title {
    -webkit-line-clamp: 3;
  }
}

@media (--pl-viewport-from-l) {
  .product-sale__percent {
    padding: 0 10px;
    height: 24px;
  }

  .product-sale__period {
    height: 24px;
  }

  .product-description {
    padding: var(--pl-unit-x1) 8px 8px;
  }
}
</style>
