import { onMounted, ref } from "vue";

export type IImageLoadStatuses = "init" | "pending" | "success" | "error";

const useLoadProductImage = (src?: string) => {
  const imageSrc = ref(
    `/images/product/product-${src ? "skeleton" : "empty"}.png`
  );
  const imageStatus = ref<IImageLoadStatuses>("init");

  onMounted(() => {
    if (!src) {
      imageStatus.value = "error";
      return;
    }
    imageStatus.value = "pending";
    const fakeImage = new Image();
    fakeImage.onload = () => {
      if (src) {
        imageStatus.value = "success";
        imageSrc.value = src;
      }
    };
    fakeImage.onerror = () => {
      imageStatus.value = "error";
      imageSrc.value = `/images/product/product-empty.png`;
    };
    fakeImage.src = src;
  });

  return { imageSrc, imageStatus };
};
export default useLoadProductImage;
